.button {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    border-radius: 3px;
    text-align: center;
    outline: none;
    user-select: none;
    min-width: 60px;
}

.full-width {
    width: 100%;
}

.text {
    display: inline-block;
    outline: none;
    user-select: none;
    cursor: pointer;
}

/* Backup for browsers that do not support :focus-visible */
.text:focus:not([disabled]):not(.prevent-underline) {
    text-decoration: underline;
    outline: var(--focus-blue) solid 3px;
    outline-offset: 2px;
}

/* Removes focus style on non-keyboard input (i.e. mouse click) */
.text:focus:not([disabled]):not(:focus-visible) {
    outline: none;
}

/* Adds focus style only on keyboard input for browsers that support :focus-visible */
.text:focus-visible:not([disabled]):not(.prevent-underline) {
    text-decoration: underline;
    outline: var(--focus-blue) solid 3px;
    outline-offset: 2px;
}

.text:hover:not([disabled]):not(.prevent-underline) {
    text-decoration: underline;
}

.primary {
    composes: button;
    color: #fff;
    background-color: var(--blue);
}

/* Backup for browsers that do not support :focus-visible */
.primary:focus:not([disabled]) {
    background-color: var(--blue-dk);
    outline: var(--focus-blue) solid 3px;
    outline-offset: 2px;
}

/* Removes focus style on non-keyboard input (i.e. mouse click) */
.primary:focus:not([disabled]):not(:focus-visible) {
    outline: none;
}

/* Adds focus style only on keyboard input for browsers that support :focus-visible */
.primary:focus-visible:not([disabled]) {
    background-color: var(--blue-dk);
    outline: var(--focus-blue) solid 3px;
    outline-offset: 2px;
}

.primary:hover:not([disabled]) {
    background-color: var(--blue-dk);
}

.secondary {
    composes: button;
    color: var(--grey);
    background-color: var(--button-secondary);
}

/* Backup for browsers that do not support :focus-visible */
.secondary:focus:not([disabled]) {
    color: var(--grey-dk);
    background-color: var(--button-secondary-dk);
    outline: var(--focus-blue) solid 3px;
    outline-offset: 2px;
}

/* Removes focus style on non-keyboard input (i.e. mouse click) */
.secondary:focus:not([disabled]):not(:focus-visible) {
    outline: none;
}

/* Adds focus style only on keyboard input for browsers that support :focus-visible */
.secondary:focus-visible:not([disabled]) {
    color: var(--grey-dk);
    background-color: var(--button-secondary-dk);
    outline: var(--focus-blue) solid 3px;
    outline-offset: 2px;
}

.secondary:hover:not([disabled]) {
    color: var(--grey-dk);
    background-color: var(--button-secondary-dk);
}

.orange {
    composes: button;
    color: #fff;
    background-color: var(--sidebar-red);
}

/* Backup for browsers that do not support :focus-visible */
.orange:focus:not([disabled]) {
    background-color: #ff4f05; /* TODO: standardize color */
    outline: var(--focus-blue) solid 3px;
    outline-offset: 2px;
}

/* Removes focus style on non-keyboard input (i.e. mouse click) */
.orange:focus:not([disabled]):not(:focus-visible) {
    outline: none;
}

/* Adds focus style only on keyboard input for browsers that support :focus-visible */
.orange:focus-visible:not([disabled]) {
    background-color: #ff4f05; /* TODO: standardize color */
    outline: var(--focus-blue) solid 3px;
    outline-offset: 2px;
}

.orange:hover:not([disabled]) {
    /* Darken 15% over above orange, until we can get on new color palette */
    background-color: #ff4f05; /* TODO: standardize color */
}

.ai-blue {
    composes: button;
    color: var(--white);
    background-color: var(--ai-blue);
}

.ai-blue:hover:not([disabled]) {
    background-color: var(--ai-hover);
}

.ai-blue:focus:not([disabled]),
.support:focus:not([disabled]) {
    outline: var(--focus-blue) solid 3px;
    outline-offset: 2px;
}

.support {
    composes: button;
    color: var(--white);
    background-color: var(--support);
}

.support:hover:not([disabled]) {
    background-color: var(--support-hover);
}

.save {
    composes: button;
    color: #fff;
    background-color: var(--green);
}

/* Backup for browsers that do not support :focus-visible */
.save:focus:not([disabled]) {
    background-color: var(--green-dk);
    outline: var(--focus-blue) solid 3px;
    outline-offset: 2px;
}

/* Removes focus style on non-keyboard input (i.e. mouse click) */
.save:focus:not([disabled]):not(:focus-visible) {
    outline: none;
}

/* Adds focus style only on keyboard input for browsers that support :focus-visible */
.save:focus-visible:not([disabled]) {
    background-color: var(--green-dk);
    outline: var(--focus-blue) solid 3px;
    outline-offset: 2px;
}

.save:hover:not([disabled]) {
    background-color: var(--green-dk);
}

.danger {
    composes: button;
    color: #fff;
    background-color: var(--rose);
}

/* Backup for browsers that do not support :focus-visible */
.danger:focus:not([disabled]) {
    background-color: var(--rose-dk);
    outline: var(--focus-blue) solid 3px;
    outline-offset: 2px;
}

/* Removes focus style on non-keyboard input (i.e. mouse click) */
.danger:focus:not([disabled]):not(:focus-visible) {
    outline: none;
}

/* Adds focus style only on keyboard input for browsers that support :focus-visible */
.danger:focus-visible:not([disabled]) {
    background-color: var(--rose-dk);
    outline: var(--focus-blue) solid 3px;
    outline-offset: 2px;
}

.danger:hover:not([disabled]) {
    background-color: var(--rose-dk);
}

.upgrade {
    composes: button;
    color: var(--blue);
    background-color: var(--white);
    border: 1px solid var(--blue);
    border-radius: 3px;
}

.upgrade:hover:not([disabled]) {
    background-color: var(--blue-xlt);
    color: var(--blue-dk);
    border-color: var(--blue-dk);
}

.text-primary {
    composes: text;
    color: var(--blue);
}

.text-primary:not(.full-width) {
    text-align: left;
}

/* Backup for browsers that do not support :focus-visible */
.text-primary:focus:not([disabled]) {
    color: var(--blue-dk);
    outline: var(--focus-blue) solid 3px;
    outline-offset: 2px;
}

/* Removes focus style on non-keyboard input (i.e. mouse click) */
.text-primary:focus:not([disabled]):not(:focus-visible) {
    outline: none;
}

/* Adds focus style only on keyboard input for browsers that support :focus-visible */
.text-primary:focus-visible:not([disabled]) {
    color: var(--blue-dk);
    outline: var(--focus-blue) solid 3px;
    outline-offset: 2px;
}

.text-primary:hover:not([disabled]) {
    color: var(--blue-dk);
}

.text-secondary {
    composes: text;
    color: var(--grey-lt);
}

/* Backup for browsers that do not support :focus-visible */
.text-secondary:focus:not([disabled]) {
    color: var(--grey);
    outline: var(--focus-blue) solid 3px;
    outline-offset: 2px;
}

/* Removes focus style on non-keyboard input (i.e. mouse click) */
.text-secondary:focus:not([disabled]):not(:focus-visible) {
    outline: none;
}

/* Adds focus style only on keyboard input for browsers that support :focus-visible */
.text-secondary:focus-visible:not([disabled]) {
    color: var(--grey);
    outline: var(--focus-blue) solid 3px;
    outline-offset: 2px;
}

.text-secondary:hover:not([disabled]) {
    color: var(--grey);
}

.text-danger {
    composes: text;
    color: var(--rose);
}

/* Backup for browsers that do not support :focus-visible */
.text-danger:focus:not([disabled]) {
    color: var(--rose-dk);
    outline: var(--focus-blue) solid 3px;
    outline-offset: 2px;
}

/* Removes focus style on non-keyboard input (i.e. mouse click) */
.text-danger:focus:not([disabled]):not(:focus-visible) {
    outline: none;
}

/* Adds focus style only on keyboard input for browsers that support :focus-visible */
.text-danger:focus-visible:not([disabled]) {
    color: var(--rose-dk);
    outline: var(--focus-blue) solid 3px;
    outline-offset: 2px;
}

.text-danger:hover:not([disabled]) {
    color: var(--rose-dk);
}

.text-add {
    composes: text-primary;
}

.text-add:before {
    content: '+ ';
}

.text.big,
.text-primary.big,
.text-secondary.big,
.text-danger.big {
    font-size: var(--font-normal);
}

.mini {
    padding: 2px 4px;
    font-size: 11px;
}

.tiny {
    padding: 2px 4px;
    font-size: 12px;
}

.small {
    padding: 4px 10px 3px 10px;
    font-size: var(--font-small);
}

.normal {
    padding: 4px 10px;
    font-size: var(--font-small);
}

.big {
    padding: 7px 12px;
    font-size: var(--font-small);
}

.xlarge {
    padding: 12px 24px;
    font-size: var(--font-normal);
}

.button[disabled] {
    opacity: 0.6;
    cursor: default;
}

.text[disabled] {
    color: var(--off-white-dk);
    cursor: default;
    text-decoration: none;
}

.light[disabled] {
    color: var(--off-white-dk);
    background-color: var(--off-white);
}

.no-padding {
    padding: 0;
}

.fit-content {
    width: fit-content;
}

/* Adapted from https://codemyui.com/wiggle-effect-cta-button-css/ */
@keyframes btnWiggle {
    50% {
        transform: rotate(0deg);
    }
    55% {
        transform: rotate(-2deg);
    }
    58.75% {
        transform: rotate(2deg);
    }
    62.5% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

.wiggle {
    animation: btnWiggle 2s 1;
}

.text-align-left {
    text-align: left;
}

.text-align-right {
    text-align: right;
}

.text-align-center {
    text-align: center;
}
