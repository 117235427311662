.body {
    font-weight: normal;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Font sizes and line heights
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
.mini {
    font-size: 11px;
    line-height: 14px;
}

.tiny {
    font-size: 12px;
    line-height: 15px;
}

.small {
    font-size: var(--font-small);
    line-height: 17px;
}

.normal {
    font-size: var(--font-normal);
    line-height: 19px;
}

.large {
    font-size: var(--font-big);
    line-height: 22px;
}

.xlarge {
    font-size: var(--font-huge);
    line-height: 1.5;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * Font colors and weights
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
.light {
    color: var(--grey-lt);
}

.medium {
    color: var(--grey);
}

.dark {
    color: var(--grey-dk);
}

.person {
    color: var(--person);
}

.error {
    color: var(--rose);
}

.blue {
    color: var(--blue);
}

.white {
    color: var(--white);
}

.green {
    color: var(--success);
}

.blue-dk {
    color: var(--blue-dk);
}

.navy-dk {
    color: var(--sidebar-primary);
}

.warn {
    color: var(--warning);
}

.placeholder {
    color: var(--placeholder);
}

.bold {
    font-weight: 500;
}

.italic {
    font-style: italic;
}

.underline {
    text-decoration: underline;
}

.strikethrough {
    text-decoration: line-through;
}

.truncate {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.truncate-inline {
    max-width: 100%;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.nowrap {
    white-space: nowrap;
}

.wrap-anywhere {
    overflow-wrap: anywhere;
}
