.container {
    display: flex;
    flex-direction: column;
    border-radius: 2px;
    overflow: hidden;
    box-shadow:
        0 8px 10px 1px rgba(0, 0, 0, 0.14),
        0 3px 14px 2px rgba(0, 0, 0, 0.12),
        0 5px 5px -3px rgba(0, 0, 0, 0.2);
}

.option-container {
    display: flex;
    flex-direction: row;
    background-color: #fff;
    height: 40px;
    min-width: 150px;
    font-size: 15px;
    align-items: center;
    box-sizing: border-box;
    padding: 0 16px;
}

.option-container a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.option-container--enabled {
    composes: option-container;
    color: var(--grey-dk);
}

.option-container--disabled {
    composes: option-container;
    color: var(--grey-lt);
    user-select: none;
}

.option-container--enabled:hover,
.option-container--enabled:focus-visible {
    cursor: pointer;
    background-color: var(--color-list-hovered);
}

.option-container--disabled:hover,
.option-container--disabled:focus-visible {
    cursor: default;
}

.option-container--submenu {
    composes: option-container;
    height: 44px;
}

.option-container--primary {
    composes: option-container;
    color: var(--blue);
}

.option-container--secondary {
    composes: option-container;
}

.option-container--destructive {
    composes: option-container;
    color: var(--rose);
}

.option-container--destructive > .icon-container {
    color: var(--grey-dk);
}

.icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 24px;
    margin-right: 8px;
}

.icon-container svg {
    fill: var(--grey-dk);
}

.icon-container :global(.fa) {
    color: var(--grey-dk);
}

.option-container--disabled .icon-container svg {
    fill: var(--grey-lt);
}

.option-container--disabled .icon-container :global(.fa) {
    color: var(--grey-lt);
}
